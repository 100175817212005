@font-face {
    font-family: 'Rubik';
    src: url('./assets/fonts/Rubik.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}
  
@font-face {
    font-family: 'Source Han Sans CN Heavy';
    src: url('./assets/fonts/Source Han Sans CN Heavy.otf') format('opentype');
    font-weight: normal;
    font-style: normal;
}

/* Default font (for English) */
.titleFontEnglish {
    font-family: 'Stretch Pro', sans-serif;
}
  
/* Russian font */
.titleFontRussian {
    font-family: 'Rubik', sans-serif !important;
}
  
/* Chinese font */
.titleFontChinese {
    font-family: 'Source Han Sans CN Heavy', sans-serif !important;
}

body {
    width: 100vmax;
    height: 100vmin;
    font-family: 'Helvetica', 'sans-serif';
}

.casesSectionContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    width: auto;
    height: 50vw;
    margin-top: 30vw;
}

.casesSectionContent {
    display: flex;
    width: auto;
    height: 40vw;
    width: 80vw;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    position: relative;
    left: 7vw;
    gap: 1vw;
}

.casesTitle {
    font-family: 'Stretch Pro', sans-serif;
    font-size: 6vmin;
    font-weight: 500;
}

.caseBox {
    border-radius: 2vw;
    background-color: #F3F3F3;
    width: 65vw;
    height: 31vw;
    display: flex;
    justify-content: center;
    align-items: center;
}

.caseBoxContent {
    height: 25vw;
    width: 60vw;
    display: flex;
}

.caseImgWrapper {
    height: 25vw;
    width: 30vw; /* Set specific width for this wrapper */
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: visible; /* Ensure that the images overflow the wrapper if needed */
    flex-shrink: 0; /* Prevent the wrapper itself from shrinking */
}

.caseImg {
    height: 100%; /* Keep height consistent with the wrapper */
    width: 20vw; /* Maintain the original width of the image */
    max-width: none; /* Remove any width constraints */
    border-radius: 2vw;
    position: relative;
    flex-shrink: 0; /* Prevent the image from shrinking */
}

.casePhoto {
    position: relative;
    left: 5vw;
    z-index: 1;
    background-repeat: no-repeat;
}

.caseCertificate {
    position: relative;
    right: 8vw;
    background-size: cover; /* Ensure the image covers the div */
    background-position: center; /* Center the background image */
}

.caseInfoWrapper {
    display: flex;
    flex-direction: column;
    gap: 1vw;
}

.caseName {
    font-size: 36px;
}


.caseBio {
    font-size: 16px;
    width: 30vw;
    color: grey;
    margin-left: 5px;
}

.caseHighlightsWrapper {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-top: 1vw;
}

.caseHighlight {
    background-color: #E2E2E2;
    color: grey;
    border-radius: 25px;
    height: 40px; /* Fixed height for the container */
    text-align: center; /* Horizontally center the text */
    padding: 1em; /* Padding around the text */
    font-size: 16px; /* Define a base font size */
    display: inline-flex; /* Use inline-flex to allow both horizontal and vertical centering */
    align-items: center; /* Vertically center the text */
    justify-content: center; /* Horizontally center the text */
    max-width: fit-content; /* Only take as much width as content requires */
    white-space: nowrap; /* Prevent text from wrapping */
}

.caseName:hover {
    cursor: default;
}

.caseBio:hover {
    cursor: default;
}

.caseHighlight:hover {
    cursor: default;
}

.caseControls {
    width: 8vw;
    height: 5vw;
    position: absolute;
    left: 57vw;
    top: 33vw;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
}

.caseControls button{
    width: 2.5vw;
    height: 2.5vw;
    border-radius: 2vw;
    border: none;
    cursor: pointer;
    position: relative;
    background-color: #fff; /* Adjust as needed */
}

.caseControls button:disabled{
    opacity: 0.5;
}

.arrowLeft {
    border: solid black;
    border-width: 0 1px 1px 0;
    display: inline-block;
    padding: 4px;
    transform: rotate(135deg);
    -webkit-transform: rotate(135deg);
    position: relative;
    left: 2px;
}

.arrowRight {
    border: solid black;
    border-width: 0 1px 1px 0;
    display: inline-block;
    padding: 4px;
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
    position: relative;
    right: 2px;
}



.bgObjectStar {
    --initial-width: 20vw;
    width: var(--initial-width);
    height: auto;
    left: -12vw;
    top: 32vw;
    z-index: 2 !important;
    filter: hue-rotate(-15deg);
}

.bgObjectPin {
    --initial-width: 14vw;
    width: var(--initial-width);
    height: auto;
    left: 35vw;
    top: 50vw;
    z-index: 2 !important;
    /* filter: hue-rotate(60deg) saturate(120%) brightness(100%) contrast(110%); */
}

.bgObjectSpiral {
    --initial-width: 16vw;
    width: var(--initial-width);
    height: auto;
    left: 55vw;
    top: 19vw;
}




/* Mobile */
@media (max-width: 414px) {
    html, body {
        overflow-x: hidden; /* Disable horizontal scrolling */
        min-width: 100vw; /* Ensure the width is set to the viewport width */
        margin: 0;
        padding: 0;
    }
    
    .casesSectionContainer {
        margin-top: 220vw;
        height: 170vw;
        width: 100vw;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        overflow-x: hidden;
    }

    .casesSectionContent {
        top: 10vw;
        height: 130vw;
        width: 95vw;
        left: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: -50vw;
        margin-bottom: -20vw;
    }

    .casesTitleWrapper {
        width: 85%;
    }

    .casesTitle {
        position: relative;
        left: 0vw;
        text-align: left;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
    }

    .caseBox {
        height: 110vw;
        width: 85vw;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        border-radius: 5vw;
    }

    .caseBoxContent {
        height: 100vw;
        width: 80vw;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .caseImgWrapper {
        width: 80vw;
        height: 45vw;
        display: flex;
        flex-direction: column;
        position: relative;
        top: 14vw;
    }

    .caseImg {
        height: 35vw; 
        width: 65vw; 
        background-size: cover;
    }
    
    .casePhoto {
        position: relative;
        left: 0vw;
        z-index: 1;
        background-size: 80vw !important;
        background-position: -15px -160px !important;
        background-repeat: no-repeat;
    }
    
    .caseCertificate {
        position: relative;
        right: 0vw;
        bottom: 15vw;
        background-size: cover; /* Ensure the image covers the div */
        background-position: center; /* Center the background image */
    }

    .caseInfoWrapper {
        display: flex;
        flex-direction: column;
        position: relative;
        top: 17vw;
        height: 40vw;
        width: 70vw;
    }

    .caseName {
        order: 1;
        font-size: 5vw;
        margin-left: 1.5vw;
    }

    .caseBio {
        order: 3;
        font-size: 2.5vw;
        width: 90%;
        max-height: 20vw;
        position: relative;
        bottom: 7vw;
        margin-left: 2vw;
    }

    .caseHighlightsWrapper {
        flex-direction: row;
        gap: 3px;
        order: 2;
        position: relative;
        bottom: -22vw;
        left: 0vw;
        max-width: 50vw;
    }

    .caseHighlight {
        font-size: 2.5vw;
        width: 30vw;
        height: 5vw;
    }

    .caseControls {
        width: 20vw;
        height: 10vw;
        gap: 2vw;
        top: 28vw;
        left: 53vw;
    }

    .caseControls button {
        width: 8vw;
        height: 8vw;
        border-radius: 10vw;
    }

    /* Background Objects on Mobile */
    .bgObjectStar {
        --initial-width: 40vw;
        width: var(--initial-width);
        height: auto;
        left: -8vw;
        top: 132vw;
        filter: hue-rotate(-15deg);
    }
    
    .bgObjectPin {
        display: none;
    }
    
    .bgObjectSpiral {
        --initial-width: 30vw;
        width: var(--initial-width);
        height: auto;
        left: 70vw;
        top: 22vw;
    }
}