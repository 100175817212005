* {
    font-family: 'Helvetica', sans-serif;
    font-weight:600;
    font-size: 1vmax;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

html body {
    display: flex;
    justify-content: center;
    height: 100%;
}

.navBarContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100vmax;
    height: 8vmin;
    position: fixed;
    left: 0vmin;
    top: 0vmin;
    z-index: 9;
    background-color: rgba(255, 255, 255, 1); 
    backdrop-filter: blur(10px) brightness(1.1);
    /* box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); */
}

.navBarContent {
    display: flex;
    justify-content: space-between;
    width: 98vmax;
    height: 8vmin;
    align-content: center;
    align-items: center;
    position: fixed;
    position: fixed;
}

/* Logo Alignment */
.logoWrapper {
    position: relative;
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    max-width: 25vw; 
    height: 100%;
}

.logo {
    background-image: url('./assets/logo_blacktype.svg');
    background-size: 8vmax;
    background-repeat: no-repeat;
    max-width: 10vw; 
    height: 2vw;
    flex: 1;
}

.navBarContent ul {
    list-style-type: none;
}

/* Main Navigation Links */
.navWrapper {
    max-width: 50vw;
    width: 50vw;
    display: flex;
    gap: 5vw;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.navLinks {
    max-width: 70vw;
    height: 50%;
    max-height: 50%;
    width: 100%;
    display: flex;
    gap: 5vw;
    justify-content: center;
    align-items: center;
}

/* Navigation Dropdowns */
.navDropdown {
    position: relative;
    max-height: 100%;
    display: flex;
    flex-direction: column;
    gap: 1vw;
}

.navDropdownOptionsWrapper {
    display: none;
    background-color: white;
    position: absolute;
    min-height: 5vw;
    top: 100%;
    min-width: 100%;
    width: auto;
    white-space: nowrap;
    padding: 10px;
}

.navDropdownOptions {
    display: flex;
    flex-direction: column;
    gap: 1vw;
    justify-content: center;
    align-items: center;
    height: 100%;
    min-width: 100%;
    width: auto;
}

.navDropdown:hover {
    max-height: 100vw;
}

.navDropdown:hover .navDropdownOptionsWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

/* Aside Links (Sign Up, Log In, Language) */
.asideWrapper {
    width: 25vw;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.asideLinks {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.languageDropdown {
    display: flex;
    width: 16vmin;
    border: 1.5px, solid, black;
    border-radius: 20px;
    align-items: center;
    justify-content: space-between;
    position: relative;
}

.languageDropdown {
    position: relative;
    min-width: 10vmin;
}

.languageDropdown a {
    position : relative;
    right: 18px;
}

.languageDropdown a::after{
    content: '▾';
    position: relative;
    left: 5px;
}

.globe {
    width: 5vmax;
    margin: -5px;
    height: auto;
    position: relative;
    right: 13px;
}

.navBarContainer a {
    text-decoration: none;
    color: black;
}

.navBarContainer a:hover {
    color: #FFA4FF;
}

/* Responsive Mobile Styles */
.navHamburger {
    visibility: hidden;
}

.logInButton {
    display: none;
}

.signUpButton {
    display: none;
}

@media (max-width: 414px) {
    html, body {
        overflow-x: hidden; /* Disable horizontal scrolling */
        width: 100vw; /* Ensure the width is set to the viewport width */
        margin: 0;
        padding: 0;
    }

    /* Navbar Container */
    .navBarContainer {
        width: 100vw;
        height: 70px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .navBarContent {
        width: 90vw;
        max-height: 10vw;
        display: flex;
        gap: 30vw;
        justify-content: center;
        align-items: center;
    }

    /* Navbar Content */
    .asideWrapper {
        order: 1 !important;
        align-items: center;
        justify-content: flex-start;
        display: flex;

        width: 10vw;
        height: 10vw;
        top: 0;
    }

    .languagePanel {
        display: none;
    }

    /* Language Panel */
    .languagePanel.globeActive {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        position: absolute;
        top: 9vw;
        width: 25vw;
        height: 28vw;
        left: -3vw;
    }

    .languages {
        display: flex;
        flex-direction: column;      
        width: 100%;
        height: 80%;
        gap: 2vw;
    }

    .languagePanel a{
        font-size: 4vw;
    }

    .languageDropdown {
        border: none;
        max-width: 7vw;
        width: 7vw;
        min-width: 7vw;
    }

    .languageDropdown a {
        display: none;
    }

    .signUpLink, .logInLink {
        display: none;
    }

    .logoWrapper {
        order: 2 !important;
        max-width: 12vw;
        margin-top: 10px;
        align-items: center;
        justify-content: center;
    }

    .navHamburger {
        order: 3 !important;
        visibility: visible;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 1.5vw;
        width: 10vw;
        height: 7vw;
        z-index: 10;
    }

    .globe {
        visibility: visible;
        width: 15vw;
    }

    /* The Globe */
    .languagePanel.globeActive {
        background-color: white;
        visibility: visible;
        padding: 10px;
    }

    .languagePanel.globeActive li{
        visibility: visible;
    }

    /* The Logo */
    .logo {
        order: 2;
        background-image: url('./assets/sign.svg');
        background-size: 10vw;
        height: 10vw;
        width: 10vw;
    }

    /* The Hamburger Icon */

    .navHamburgerBar {
        background-color: black;
        width: 7vw;
        height: 2px;
        transition: all 0.3s ease;
    }

    .navHamburger.menuOpen .navHamburgerBar:nth-child(1) {
        transform: rotate(45deg) translate(6px, 5px);
        position: relative;
    }

    .navHamburger.menuOpen .navHamburgerBar:nth-child(2) {
        opacity: 0;
    }

    .navHamburger.menuOpen .navHamburgerBar:nth-child(3) {
        transform: rotate(-45deg) translate(-1px, 1px);
        position: relative;
        top: -9px;
    }

    .navWrapper {
        display: none;
    }
}


