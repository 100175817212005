/* Default font (for English) */
.titleFontEnglish {
    font-family: 'Stretch Pro', sans-serif;
}
  
/* Russian font */
.titleFontRussian {
    font-family: 'Rubik', sans-serif !important;
}
  
/* Chinese font */
.titleFontChinese {
    font-family: 'Source Han Sans CN Heavy', sans-serif !important;
}

.emailSentContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 15vw;
    width: 100vw;
    height: 49vw;
    font-family: 'Helvetica', 'sans-serif';
}

.emailSentContent {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 70%;
    height: 48vw;
    gap: 1vw;
}

.emailSentIcon {
    background-image: url('./assets/icons/emailSent.png');
    background-size: contain;
    background-repeat: no-repeat;
    width: 5vw;
    height: 10vw;
}

.emailTitleWrapper {
    margin-top: -6vw;
    margin-bottom: 20px;
    width: 70%;
    text-align: center;
}

.emailTitle {
    font-size: 1.9vw;
    font-family: 'Stretch Pro', sans-serif;
    line-height: 2.5vw;
}

.emailParagraphWrapper {
    width: 50%;
    margin-top: -20px;
    text-align: center;
}

.emailParagraph {
    font-size: 1.5vw;
    font-weight: 500;
    line-height: 2vw;
}


@media (max-width: 414px) {

    .emailSentContainer {
        position: relative;
        top: 10vw;
        height: 250vw;
    }

    .emailSentContent {
        width: 80vw;
        height: 130vw;
        margin-top: -100vw;
    }

    .emailSentIcon {
        width: 9vw;
        height: 10vw;
    }

    .emailTitleWrapper {
        margin-top: 0vw;
        width: 100%;
    }

    .emailTitle {
        font-size: 4vw;
        font-family: 'Stretch Pro', sans-serif;
        line-height: 4vw;
    }

    .emailParagraphWrapper {
        margin-top: -15px;
        width: 70%;
    }

    .emailParagraph {
        line-height: 2.5vw;
        font-size: 2.5vw;
    }
    
}

