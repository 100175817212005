@font-face {
    font-family: 'Rubik';
    src: url('./assets/fonts/Rubik.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}
  
@font-face {
    font-family: 'Source Han Sans CN Heavy';
    src: url('./assets/fonts/Source Han Sans CN Heavy.otf') format('opentype');
    font-weight: normal;
    font-style: normal;
}

/* Default font (for English) */
.titleFontEnglish {
    font-family: 'Stretch Pro', sans-serif;
}
  
/* Russian font */
.titleFontRussian {
    font-family: 'Rubik', sans-serif !important;
}
  
/* Chinese font */
.titleFontChinese {
    font-family: 'Source Han Sans CN Heavy', sans-serif !important;
}

.teachersSectionContainer {
    height: 600px;
    margin-top: 0px;
}

.teachersSectionContent {
    display: flex;
    align-items: center;
    justify-content: center;
}

.teachersWindow {
    background-color: #f0f0f0;
    width: 1220px;
    height: 500px;
    border-radius: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 50px;
}

.teachersWindowLeft {
    display: flex;
    flex-direction: column;
    gap: 100px;
    margin-left: 50px;
    margin-right: 5vw;
    margin-top: 50px;
}

.teachersWindowLeft h1{
    font-family: 'Stretch Pro', sans-serif;
    font-size: 48px;
    font-weight: 500;
    position: relative;
    bottom: 20px;
}

.teachersWindowLeft button {
    background-color:#F180EF;
    color: white;
    border: 0;
    border-radius: 40px;
    width: 230px;
    height: 70px;
    font-size: 24px;
    font-weight: 300;
    margin-top: -70px;
}

.teachersWindowLeft button:hover {
    background-color:#FFA4FF;
    cursor: pointer;
}

.switchesWrapper {
    display: flex;
    gap: 10px;
}

.switch {
    width: 70px; /* Increased size */
    height: 70px; /* Increased size */
    border-radius: 50%;
    position: relative;
    bottom: 30px;
}

.switch:hover {
    cursor: pointer;
}

.teachersWindowMiddle {
    display: flex;
}

.teacherPhoto {
    width: 300px;
    height: 400px;
    border-radius: 30px;
    position: relative;
    right: 50px;
    background-color: '#E2E2E2';
}

.teachersWindowRight {
    display: flex;
    flex-direction: column;
    gap: 5px;
    position: relative;
    right: 80px;
}

.teacherName {
    font-size: 36px;
}


.teacherBio {
    font-size: 16px;
    width: 250px;
    color: grey;
    margin-left: 5px;
}

.teacherRegaliaWrapper {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-top: 180px;
}

.teacherRegalia {
    background-color: #E2E2E2;
    color: grey;
    border-radius: 25px;
    height: 40px; /* Fixed height for the container */
    text-align: center; /* Horizontally center the text */
    padding: 1em; /* Padding around the text */
    font-size: 16px; /* Define a base font size */
    display: inline-flex; /* Use inline-flex to allow both horizontal and vertical centering */
    align-items: center; /* Vertically center the text */
    justify-content: center; /* Horizontally center the text */
    max-width: fit-content; /* Only take as much width as content requires */
    white-space: nowrap; /* Prevent text from wrapping */
}

.teacherName:hover {
    cursor: default;
}

.teacherBio:hover {
    cursor: default;
}

.teacherRegalia:hover {
    cursor: default;
}

/* Background Objects */
.bgObjectEyescream {
    --initial-width: 30vw;
    width: var(--initial-width);
    height: auto;
    left: -3vw;
    top: 335vw;
}

.bgObjectRings {
    --initial-width: 18vw;
    width: var(--initial-width);
    height: auto;
    left: 80vw;
    top: 338vw;
    z-index: 1;
}

/* Mobile */
@media (max-width: 414px) {
    html, body {
        overflow-x: hidden; /* Disable horizontal scrolling */
        max-width: 12vw; 
        margin: 0;
        padding: 0;
    }

    .teachersSectionContainer {
        width: 100vw;
        height: 200vw;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .teachersSectionContent {
        width: 90vw;
        margin-top: -50vw;
        margin-bottom: -40vw;
    }

    .teachersWindow {
        width: 90vw;
        height: 150vw;
        display: flex;
        flex-direction: column;
    }

    .teachersWindowLeft {
        width: 90vw;
        display: flex;
        flex-direction: column;
    }

    .teachersWindowLeft h1{
        font-size: 4.5vw;
        top: 32vw;
        
    }

    .teachersWindowLeft button {
        position: relative;
        right: 0;
        left: 50vw;
        top: 130vw;
        margin: 0;
        width: 33vw;
        height: 10vw;
        font-size: 4vw;
    }

    .switchesWrapper {
        position: relative;
        top: 103vw;
        right: -3vw;
        gap: 1vw;
    }

    .switch {
        width: 9vw;
        height: 9vw;
    }

    .teachersWindowMiddle {
        width: 90vw;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .teacherPhoto {
        left: 0;
        width: 80vw;
        height: 90vw;
        bottom: 45vw;
    }

    .teachersWindowRight {
        width: 80vw;
        height: 30vw;
        right: 0;
        bottom: 55vw;
        display: flex;
        flex-direction: column;
    }

    .teacherName {
        font-size: 5vw;
        margin-top: 1vw;
        margin-left: 1vw;
    }

    .teacherBio, .teacherRegalia {
        font-size: 4vw;
        font-weight: 400;
    }

    .teacherRegaliaWrapper {
        margin-top: 0.5vw;
        flex-direction: row;
        position: relative;
        gap: 1px;
    }

    .teacherRegalia {
        height: 4vw;
        font-size: 3vw;
    }

    .teacherRegaliaWrapper, .teacherBio, .teacherName {
        position: relative;
        left: 1vw;
    }

    /* Mobile Background Objects */
    .bgObjectEyescream {
        position: relative;
        --initial-width: 90vw;
        width: var(--initial-width);
        height: 90vw;
        min-height: 90vw;
        left: 1vw;
        top: 55vw;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        -webkit-background-size: cover;
        -webkit-background-position: center;
        border: 2px solid transparent;
    }

    .bgObjectRings {
        border: 2px solid transparent;
        position: relative;
        --initial-width: 40vw;
        width: var(--initial-width);
        height: auto;
        left: 24vw;
        top: 165vw;
        z-index: 1;
        display: none;
    }
}

