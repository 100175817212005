@font-face {
    font-family: 'Rubik';
    src: url('./assets/fonts/Rubik.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}
  
@font-face {
    font-family: 'Source Han Sans CN Heavy';
    src: url('./assets/fonts/Source Han Sans CN Heavy.otf') format('opentype');
    font-weight: normal;
    font-style: normal;
}

/* Default font (for English) */
.titleFontEnglish {
    font-family: 'Stretch Pro', sans-serif;
}
  
/* Russian font */
.titleFontRussian {
    font-family: 'Rubik', sans-serif !important;
}
  
/* Chinese font */
.titleFontChinese {
    font-family: 'Source Han Sans CN Heavy', sans-serif !important;
}

body {
    font-family: 'Helvetica', 'sans-serif';
    
}

.coursesSection {
    width: 100vmax;
    height: 100vmin;
    display: flex;
    flex-direction: column;
    text-align: left;
    align-items: center;
}

.coursesSectionContent {
    width: 90%;
    position: relative;
    left: 20px;
}

.coursesSectionTitle {
    font-family: 'Stretch Pro', sans-serif;
    font-size: 6vmin;
    font-weight: 500;
    margin-bottom: 10px;
    margin-left: 20px;
}

.coursesWrapper {
    width: 50%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 6%; 
    position: relative;
}

.courseBox {
    width: 600px;
    height: 480px;
    border-radius: 40px;
    background-size: 800px; 
    display: flex;
    justify-content: center;
    align-items: center;
}

.course1 {
    background-color: #FFA4FF;
    background-image: url('./assets/courses/English.png');
    background-position: 120px 0px;
}

.course2 {
    background-image: url('./assets/courses/IELTS.png');
    background-color: #F7D8FF;
    background-position: 50px 0px;
    background-size: 800px;
}

.course3 {
    background-image: url('./assets/courses/Mandarin.png');
    background-color: #F7D8FF;
    background-position: -70px 0px;
}

.course4 {
    background-image: url('./assets/courses/HSK.png');
    background-color: #FFA4FF;
    background-position: 40px 0px;
}

.courseInterfaceWrapper {
    display: flex;
    flex-direction: column;
    gap: 6vw;
    height: 90%;
    width: 90%;
    display: flex;
}

.courseInfoWrapper {
    width: 60%;
    height: 60%;
    display: flex;
    flex-direction: column;
    gap: 0.5vw;
}

.courseTitle {
    font-size: 48px;
    width: 40%;
    font-family: 'Helvetica', sans-serif !important;
    color: #222222;
    line-height: 1;
    max-height: 12vw;
    min-height: 1vw;
    min-width: 20vw;
}

.courseSubtitle {
    font-size: 20px;
    width: 40%;
    position: relative;
    left: 5px;
    color:grey;
    min-height: 5vw;
    min-width: 15vw;
    width: 15vw !important;
    margin-top: 0vw;
}

.circleIcon {
    width: 80px; /* Increased size */
    height: 80px; /* Increased size */
    background-color: white;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
    position: relative;
    cursor: pointer;
    overflow: hidden; /* Ensures content stays within bounds */
    transition: all 0.3s ease-in-out;
    padding: 0 10px; /* Padding for smoother expansion */
    position: relative;
    top: 170px;
    text-align: left;
    position: sticky;
}

/* Initially hidden text */
.circleText {
    opacity: 0; /* Fully hidden */
    visibility: hidden; /* Makes sure the text doesn’t affect layout */
    position: absolute;
    font-size: 18px; /* Adjusted for the larger circle */
    white-space: nowrap; /* Prevents wrapping */
    color: black;
    transition: all 0.1s ease-in-out;
    pointer-events: none; /* Prevents the text from affecting interactions */
}

/* Arrow styling */
.arrow {
    width: 35px; /* Larger arrow shaft */
    height: 4px;  /* Slightly thicker */
    background-color: black;
    position: relative;
    transition: transform 0.1s ease-in-out;
}

.arrow::before, .arrow::after {
    content: '';
    position: absolute;
    width: 25px; /* Larger chevron lines */
    height: 4px;  /* Match the thickness of the shaft */
    background-color: black;
    transform-origin: center;
}

.arrow::before {
    transform: rotate(45deg);
    right: -7px; /* Adjusted for larger arrow */
    top: -7.5px;  /* Adjusted for larger arrow */
}

.arrow::after {
    transform: rotate(-45deg);
    right: -7px; /* Adjusted for larger arrow */
    top: 7.5px;  /* Adjusted for larger arrow */
}

/* Hover effect */
.courseBox:hover .circleIcon {
    width: 180px; /* Larger expansion to fit text */
    padding: 0 30px; /* Adjust padding for the larger circle */
    height: 80px; /* Larger oval height */
    border-radius: 100px; /* Keeps it an oval */
}

.courseBox:hover .arrow {
    transform: translateX(60px); /* Move the arrow further for larger size */
}

.courseBox:hover .circleText {
    font-size: 20px;
    opacity: 1; /* Show text */
    visibility: visible; /* Text becomes visible */
    transform: translateX(-10px); /* Slight adjustment */
}

.discountInfo {
    font-size: 20px;
    font-weight: 100;
    margin-top: 80px;
    text-align: center;
}

/* Mobile */
@media (max-width: 768px) {
    html, body {
        overflow-x: hidden; /* Disable horizontal scrolling */
        width: 100vw; /* Ensure the width is set to the viewport width */
        margin: 0;
        padding: 0;
    }
    
    .coursesSection {
        height: 440vw;
        position: relative;
        top: 0vw;
        width: 100vw;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: -220vw;
    }

    .coursesSectionContent {
        width: 100vw;
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        left: 0;
        margin-top: -60vw;
        margin-bottom: -70vw;
    }

    .coursesSectionTitle {
        width: 100vw;
        margin-left: 15vw;
    }

    .coursesWrapper {
        width: 90vw;
        display: flex;
        flex-direction: column;
        gap: 10vw;
    }

    .courseBox {
        width: 90vw;
        height: 90vw;
    }

    .courseInterfaceWrapper {
        width: 80vw;
        gap: 15vw;
    }

    .courseBox h2{
        font-size: 10vw;
    }

    .courseBox p{
        font-size: 4vw;
        min-width: 44vw;
    }

    .course1 {
        background-color: #FFA4FF;
        background-position: 20px 100px;
        background-size: 140vw;
        background-repeat: no-repeat;
    }
    
    .course2 {
        background-color: #F7D8FF;
        background-position: 20px 110px;
        background-size: 120vw;
        background-repeat: no-repeat;
    }
    
    .course3 {
        background-color: #FFA4FF;
        background-position: -100px 100px;
        background-size: 140vw;
        background-repeat: no-repeat;
    }
    
    .course4 {
        background-color: #F7D8FF;
        background-position: 30px 80px;
        background-size: 120vw;
        background-repeat: no-repeat;
    }

    .courseInfoWrapper {
        display: flex;
        flex-direction: column;
    }

    .courseTitle {
        min-width: 50vw;
        max-height: 23vw;
    }

    .courseSubtitle {
        min-height: 20vw;
    }

    .circleIcon {
        width: 15vw;
        height: 15vw;
    }

    /* Hover effect */
    .courseBox:hover .circleIcon {
        width: 45vw;
        height: 15vw;
        padding: 0 30px; 
        border-radius: 100px; 
        
    }

    .discountInfo {
        width: 80vw;
        font-size: 3vw;
        position: relative;
        top: -10vw;
    }
}
