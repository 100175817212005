@font-face {
    font-family: 'Rubik';
    src: url('./assets/fonts/Rubik.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}
  
@font-face {
    font-family: 'Source Han Sans CN Heavy';
    src: url('./assets/fonts/Source Han Sans CN Heavy.otf') format('opentype');
    font-weight: normal;
    font-style: normal;
}

/* Default font (for English) */
.titleFontEnglish {
    font-family: 'Stretch Pro', sans-serif;
}
  
/* Russian font */
.titleFontRussian {
    font-family: 'Rubik', sans-serif !important;
}
  
/* Chinese font */
.titleFontChinese {
    font-family: 'Source Han Sans CN Heavy', sans-serif !important;
}

.successPopup {
    position: fixed;
    bottom: -50px; /* Initially hidden below the screen */
    left: 50%;
    transform: translateX(-50%);
    background-color: white;
    color: black;
    padding: 15px 20px;
    border-radius: 5px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    font-size: 16px;
    font-weight: bold;
    z-index: 1000;
    transition: bottom 0.4s ease-in-out, opacity 0.4s ease-in-out;
    opacity: 0;
}

.successPopupVisible {
    bottom: 20px; /* Visible position, slides up */
    opacity: 1; /* Make it fully visible */
}

.popupTitle {
    font-family: 'Stretch Pro', sans-serif;
    font-size: 36px;
    font-weight: 100;
    margin-bottom: 20px;
    margin-left: 0; /* Align the demo title to the left */
    letter-spacing: -1px; /* Slightly decrease kerning to reduce space between letters */
    width: 350px;
}

.popupSign {
    width: 145px;
    position: relative;
    left: 180px;
    top: 25px;
    transform: rotate(-7deg);
}

.letterSpacing {
    letter-spacing: -11px;
    font-family: 'Stretch Pro', sans-serif;
    font-size: 36px;
}

/* Overlay for background dimming */
.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%; /* Ensure the overlay covers the entire viewport width */
    height: 100%; /* Ensure the overlay covers the entire viewport height */
    background-color: rgba(0, 0, 0, 0); /* Initially transparent */
    transition: background-color 0.5s ease; /* Smooth transition */
    z-index: 0; /* Ensure the overlay is above the background objects */
    pointer-events: none; /* Disable clicks on the overlay when not visible */
}

.overlayVisible {
    background-color: rgba(0, 0, 0, 0.5); /* Fade in background when visible */
    z-index: 10; /* Ensure it's above everything else but below the pop-up */
    pointer-events: all; /* Enable clicks on the overlay when visible */
}

/* Pop-up modal */
.popup {
    position: fixed;
    top: -100%; /* Starting point slightly off */
    left: 50%;
    transform: translateX(-50%);
    background-color: #f5f5f5; /* Light grey for slight contrast */
    padding: 50px; /* Increased padding */
    width: 800px; /* Adjusted size */
    height: 500px; /* Increased height */
    border-radius: 25px; /* Increased border-radius */
    opacity: 0;
    transition: top 0.8s cubic-bezier(0.4, 0, 0.2, 1), opacity 0.8s ease; /* Smooth transition */
    box-shadow: 0px 6px 20px rgba(0, 0, 0, 0.15); /* Enhanced shadow for depth */
    z-index: 1000;
    font-family: 'Helvetica', sans-serif;
}

.popupVisible {
    top: 50%; /* Slide down to center */
    transform: translate(-50%, -50%);
    opacity: 1; /* Make it visible */
}

/* Pop-up close button */
.closeIcon {
    position: absolute;
    top: 5px;
    right: 15px;
    font-size: 28px;
    font-weight: 100;
    cursor: pointer;
}

/* Flexbox container for content */
.popupContent {
    display: flex;
    flex-direction: column;
    justify-content: space-between; /* Align steps and form side by side */
    gap: 10px; /* Reduced gap */
    height: 100%; /* Ensure full height */
}

.popupTop {
    display: flex;
}

.popupMain {
    display: flex;
}

/* Left side where title and steps are */
.popupLeftSide {
    flex: 1;
    width: 50%;
}

/* Steps section */
.steps {
    display: flex;
    flex-direction: column;
    position: relative;
    align-items: flex-start;
    bottom: 10px;
    left: 10px;

    
}

.stepItem {
    display: flex;
    align-items: center;
    margin-bottom: 30px; /* Increased spacing */
    position: relative;
    font-size: 40px; /* Increase text size */
    width: 100%;
    height: 100%;
    min-height: 50px;
    
}

.stepItem p{
    font-size: 20px; 
    font-weight: 500;
    position: relative;
    left: 50px;
    font-family: 'Helvetica', sans-serif;
    width: 20vw;
}

.circle {
    width: 35px; /* Larger circle */
    height: 35px;
    border: 1.5px solid black;
    border-radius: 50%;
    margin-right: 15px;
    position: absolute;
    z-index: 1;
}

/* Lines connecting circles */
.lineTop {
    position: absolute;
    left: 16.5px; /* Center the line with the circle */
    width: 1px;
    background-color: black;
}

.lineTop {
    top: -38px;
    height: 45px;
}

/* Form section */
.formSection {
    display: flex;
    flex-direction: column;
    width: 50%;
    position: relative;
    bottom: 50px;
}

/* Input field styling */
.inputField {
    padding: 15px;
    background-color: white; /* White background */
    border: none; /* No border */
    border-radius: 5px;
    margin-bottom: 5px; /* Reduced the gap between input fields */
    font-size: 20px;
    font-weight: 300;
    width: 100%; /* Full width */
    height: 55px;
}

/* Button color based on your image */
.submitButton {
    background-color: #F180EF;    
    color: white;
    padding: 15px;
    border: none;
    border-radius: 5px;
    font-size: 20px;
    cursor: pointer;
    margin-top: 15px; /* Reduced margin-top */
    animation: buttonAppear 1s ease;
    width: 100%; /* Full width to align with input fields */
    height: 65px;
}

.submitButton:hover {
    background-color: #FFA4FF;
}

.error {
    color: lightgrey; /* Make it more noticeable for the user */
    font-size: 14px;
    margin-bottom: 0px; /* Space between error message and input */
    min-height: 20px; /* Reserve space for the error message */
    visibility: hidden; /* Hide the error message without removing it from the layout */
}

.errorVisible {
    visibility: visible; /* Show the error when needed */
}

.inputWrapper {
    position: relative;
    margin-bottom: 0px; /* Reduced margin */
    min-height: 85px; /* Reserve space for input and error message together */
}

/* Animation for button descending slightly */
@keyframes buttonAppear {
    from {
        transform: translateY(-20px);
        opacity: 0;
    }
    to {
        transform: translateY(0);
        opacity: 1;
    }
}

/* Loader for button */
.loader {
    border: 3px solid #f3f3f3; /* Light grey */
    border-top: 3px solid #F180EF; /* Button color */
    border-radius: 50%;
    width: 25px;
    height: 25px;
    animation: spin 1s linear infinite;
    display: inline-block;
    vertical-align: middle;
    margin-right: 10px;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

/* Popup on Mobile */
@media (max-width: 414px) {
    html, body {
        overflow-x: hidden; /* Disable horizontal scrolling */
        min-width: 100vw; /* Ensure the width is set to the viewport width */
        margin: 0;
        padding: 0;
    }

    .popup {
        width: 90vw;
        height: 130vw;
    }

    .popup .circle{
        display: none;
    }

    .popup .lineTop{
        display: none;
    }

    .popup .steps {
        display: none;
    }

    .popupTitle {
        position: relative;
        top: 4vw;
        left: 3vw;
        min-width: 50vw;
    }

    .popupTitle, .letterSpacing { 
        font-size: 5.3vw;
    }

    .letterSpacing {
        letter-spacing: -1.5vw;
    }

    .popupSign {
        left: -6vw;
        top: 14vw;
        width: 20vw;
    }

    .popup .formSection {
        display: flex;
        width: 100vw;
        height: 7vw;
        position: relative;
        bottom: 80vw;
        right: 2vw;
    }

    .popup .inputField {
        margin-bottom: 0vw; /* Reduced gap on mobile */
        height: 15vw;
        width: 70vw;
    }
    
    .popup .submitButton {
        font-size: 5vw;
    }

    .successPopup {
        width: 60vw;
        font-size: 3vw;
    }

    .error {
        margin-bottom: -2px;
    }
}

/* Popup on Small Mobile Devices */
@media (max-width: 375px) {
    .popup {
        width: 95vw;
        padding: 40px;
    }

    .popupTitle {
        font-size: 5vw;
    }

    .popupSign {
        width: 18vw;
        left: -2vw;
    }

    .formSection {
        bottom: 4vw;
    }
}
