/* Menu Outline */
.menuContainer {
    position: fixed;
    top: 16vw;
    height: 216.5vw;
    width: 100vw;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    background-color: white;
    z-index: 3;
}

.menuContent {
    margin-top: 2vw;
    height: 70%;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 2vw;
}

.menuContent a {
    text-decoration: none;
    color: black;
    font-weight: 600;
}

/* Menu Navigation */
.menuNavWrapper {
    height: 90%;
    width: 100%; /* Ensure the full width is taken */
}

.menuNav {
    min-height: 50vw;
    max-height: 140vw;
    width: 100%; /* Ensure the menu takes the full width */
    display: flex;
    flex-direction: column;
    gap: 2vw;
    align-items: center; /* Align items in the center */
    justify-content: flex-start; /* Align links vertically */
}

.menuNav:nth-child(1) {
    border-top: 1px solid #E4E4E4;
}

/* Ensure links and arrow are vertically and horizontally centered */
.menuNav li {
    width: 100%; /* Ensure the list items take full width */
    border-bottom: 1px solid #E4E4E4;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}

.menuNav a {
    font-size: 5vw;
    display: flex; /* Use flexbox to center text and arrow */
    align-items: center; /* Center vertically */
    justify-content: center; /* Center horizontally */
    width: 100%; /* Make the links take full width */
    text-align: center;
    padding: 1vw 5vw; /* Add padding around the link */
    position: relative;
    font-weight: 100;
}

/* Dropdown arrow - only for the top-level link */
.menuNavDropdown > a::after {
    content: '>'; /* The arrow */
    font-size: 5vw;
    font-weight: 100;
    color: grey;
    position: absolute;
    right: 20px; /* Position it to the right */
    transition: transform 0.3s ease; /* Smooth transition for rotation */
}

/* Rotate arrow when dropdown is open */
.menuNavDropdown.open > a::after {
    transform: rotate(90deg); /* Rotate the arrow to point down */
}

/* Dropdown options hidden by default */
.menuNavDropdownOptionsWrapper {
    display: none;
    flex-direction: column;
    width: 100%; /* Make sure the dropdown takes full width */
}

.menuNavDropdownOptionsWrapper.open {
    display: flex;
}

.menuNavDropdownOptions {
    list-style-type: none;
    padding: 0;
    margin-top: 0vw;
    width: 100%; /* Ensure the dropdown options take full width */
}

.menuNavDropdownOptions li {
    width: 100%; /* Full width for the list items */
    text-align: center;
    border-bottom: 1px solid #E4E4E4;
    padding: 1vw;
}

/* Dropdown first child border-top */
.menuNavDropdownOptions:nth-child(1) {
    border-top: 1px solid #E4E4E4;
}

.menuNavDropdown {
    display: flex;
    flex-direction: column;
}

/* Bottom Buttons */
.menuButtons {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 2vw;
    width: 100%;
    height: 20vw;
}

.menuButtons a {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 45vw;
    height: 15vw;
    font-size: 5vw;
    border-radius: 1vw;
}

.logInButton {
    order: 1 !important;
    border: 2.5px solid black;
    display: flex;
    visibility: visible;
}

.signUpButton {
    order: 2 !important;
    background-color: #F180EF;
    display: flex;
    visibility: visible;
}
