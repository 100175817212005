.testContainer {
    width: 100vw;
    height: 50vw;
    margin-top: 5vw;
    display: flex;
    justify-content: center;
    align-items: center;
}

.testContent {
    width: 90%;
    height: 60%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 5vw;
}

.bgObjectBall {
    left: 30vw;
    bottom: 5vw;
    min-width: 40vw;
}

.testTitleWrapper {
    margin-top: 7vw;
}

.testTitle {
    font-family: 'Stretch Pro', 'sans-serif';
    font-size: 2.5vw;
}

.testFeaturesWrapper {
    width: 45%;
    display: flex;
    gap: 0.4vw;
}

.testFeature {
    font-size: 1.2vw;
    background-color: #FAFAFA;
    color: #9B9B9B;
    border-radius: 25px;
    height: 30px; /* Fixed height for the container */
    text-align: center; /* Horizontally center the text */
    padding: 1em; /* Padding around the text */
    font-size: 16px; /* Define a base font size */
    display: inline-flex; /* Use inline-flex to allow both horizontal and vertical centering */
    align-items: center; /* Vertically center the text */
    justify-content: center; /* Horizontally center the text */
    max-width: fit-content; /* Only take as much width as content requires */
    white-space: nowrap; /* Prevent text from wrapping */
}

.testStartButtonWrapper {
    width: 100%;
    position: relative;
    top: 39%;
    display: flex;
    justify-content: flex-end;
}

.testStartButton {
    background-color:#F180EF;
    color: white;
    border: 0;
    border-radius: 40px;
    width: 210px;
    height: 60px;
    font-size: 24px;
    font-weight: 300;
}

.testStartButton:hover {
    cursor: pointer;
    background-color:#FFA4FF;
}


/* Mobile */
@media (max-width: 414px) {
    .bgObjectBall {
        left: 12vw !important;
        bottom: 390px !important;
        min-width: 0;
        max-width: 80vw;
        
        border: 2px solid red;
    }

    .testContainer {
        margin-top: 23vw;
        height: 120vw;

        border: 2px solid red;
    }

    .testContent {
        height: 110vw;
        gap: 0.5vw;

        border: 2px solid blue;
    }

    .testTitle {
        font-size: 4.5vw;
    }

    .testFeaturesWrapper {
        width: 85%;
    }

    .testFeature {
        height: 10px;
        font-size: 3vw;
    }

    .testStartButtonWrapper {
        justify-content: center;
        top: 40vw;
    }

    .testStartButton {
        width: 150px;
        height: 40px;
        font-size: 4.5vw;
        font-weight: 100;
    }
}