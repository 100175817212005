.comingSoonContainer {
    width: 100vw;
    height: 55vw;
    display: flex;
    justify-content: center;
    align-items: center;
}

.comingSoonContent {
    width: 80%;
    height: 30%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1vw;
    margin-bottom: 7vw;
}

.comingSoonContent h1 {
    font-family: 'Stretch Pro', sans-serif;
    font-size: 2.5vw;
}

.comingSoonContent p {
    font-family: 'Stretch Pro', sans-serif;
    font-size: 2.5vw;
}
