.SignUpContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 5vw;
    width: 100vw;
    height: 49vw;
    font-family: 'Helvetica', 'sans-serif';
}

.SignUpContent {
    display: flex;
    flex-direction: column;
    width: 30vw;
    height: 48vw;
    gap: 1vw;
}

.ContinueWithGoogle {
    border: 2px solid black;
    background-color: transparent;
    display: flex;
    gap: 4vw;
    align-items: center;
    height: 3.5vw;
    border-radius: 0.5vw;
}

.ContinueWithGoogle:hover {
    cursor: pointer;
    border: 2px solid lightgrey;
}

.ContinueWithGoogle img {
    width: 2.2vw;
    margin-left: 1vw;
}

.ContinueWithGoogle p {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 15vw;
    height: 2vw;
    font-size: 1.2vw;
    font-weight: 500;
}

.SignUpContent .separationWrapper {
    display: flex;
    height: 3vw;
    width: 100%;
    justify-content: space-between;
    align-items: center;
}

.SignUpContent .hrWrapper {
    display: flex;
    justify-items: center;
    align-items: center;
    text-align: center;
    width: 14vw;
    height: 100%;
}

.SignUpContent .separationWrapper hr {
    width: 100%;
    height: 1px;
    align-self: center;
}

.SignUpContent .separationWrapper p {
    font-weight: 100;
}

.formWrapper {
    display: flex;
    flex-direction: column;
    gap: 1.5vw;
}

.fieldWrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.formWrapper label {
    font-weight: 300;
    font-size: 1.2vw;
    margin-bottom: 0.5vw;
}

.inputWrapper {
    width: 100%;
    display: flex;
}

.inputWrapper input {
    width: 100%;
    height: 3vw;
    border: 2px solid #C9C9C9;
    border-radius: 5px;
    padding: 10px;
    font-weight: 100;
}

.eye {
    width: 1.5vw;
    position: absolute;
    left: 62.5vw;
    margin-top: 0.8vw;
}

.eye:hover {
    cursor: pointer;
}

.rememberMeWrapper {
    display: flex;
    align-items: center;
    gap: 0.5vw;
    padding-left: 5px;
}

.rememberMeWrapper input {
    transform: scale(1.5);
    accent-color: #FFA4FF;
}

.rememberMeWrapper label {
    color: gray;
    margin-top: 7px;
}

.registerButton {
    width: 100%;
    height: 3.2vw;
    border: none;
    background-color: #F180EF;
    font-size: 1.3vw;
    font-weight: 500;
    border-radius: 10px;
}

.registerButton:hover {
    cursor: pointer;
    background-color: #FFA4FF;
}

.error {
    color: rgb(255, 115, 115);
}

@media (max-width: 414px) {
    .SignUpContainer {
        position: relative;
        top: 10vw;
        height: 250vw;
    }

    .SignUpContent {
        width: 80vw;
        height: 130vw;
        margin-top: -100vw;
    }

    .ContinueWithGoogle {
        min-height: 12vw;
        border-radius: 2vw;
        border: 1px, solid black;
    }

    .ContinueWithGoogle img {
        width: 6vw;
        margin-left: 3vw;
    }

    .ContinueWithGoogle p {
        font-size: 4vw;
        width: 100%;
        margin-left: -14vw;
        color: black;
    }

    .separationWrapper {
        margin-top: 4vw;
        margin-bottom: 4vw;
    }

    .separationWrapper p {
        font-size: 3vw;
    }

    .hrWrapper {
        min-width: 45%;
    }

    hr {
        min-width: 100%;
    }

    .formWrapper label{
        font-size: 3.5vw;
    }

    .inputWrapper input {
        height: 13vw;
        font-size: 3vw;
        padding: 5px;
    }

    .eye {
        width: 6vw;
        left: 80vw;
        margin-top: 4vw;

    }

    .rememberMeWrapper {
        margin-top: 2vw;
    }

    .rememberMeWrapper label {
        margin-left: 2vw;
        margin-bottom: 1.5vw;
    }

    .registerButton {
        margin-top: 2vw;
        height: 12vw;
        font-size: 5vw;
    }

    .error {
        font-size: 3vw;
    }
}

