.bgObject {
    position: absolute;
    z-index: -1;
    transition: transform 0.1s ease-out; /* Smooth transition for scroll and scale effect */
    animation: breathe 3s infinite ease-in-out; /* Continuous breathing animation */
  }

/* Breathing animation */
@keyframes breathe {
    0%, 100% {
      width: var(--initial-width); /* Back to initial width */
    }
    50% {
      width: calc(var(--initial-width) + 0.8%); /* Increase width by 5% */
    }
}


/* Objects' behavior on mobile */
@media (max-width: 414px) {
  .bgObject {
    width: 100vw; /* Ensure full width on mobile */
    height: auto; /* Adjust height accordingly */
  }
}