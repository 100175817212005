@font-face {
    font-family: 'Rubik';
    src: url('./assets/fonts/Rubik.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}
  
@font-face {
    font-family: 'Source Han Sans CN Heavy';
    src: url('./assets/fonts/Source Han Sans CN Heavy.otf') format('opentype');
    font-weight: normal;
    font-style: normal;
}

/* Default font (for English) */
.titleFontEnglish {
    font-family: 'Stretch Pro', sans-serif;
}
  
/* Russian font */
.titleFontRussian {
    font-family: 'Rubik', sans-serif !important;
}
  
/* Chinese font */
.titleFontChinese {
    font-family: 'Source Han Sans CN Heavy', sans-serif !important;
}

.reviewsSectionContainer {
    height: 750px;
    margin-top: 0vw;
}

.reviewsSectionContent {
    margin-left: 40px;
}

.reviewsSectionTitle {
    font-family: 'Stretch Pro', sans-serif;
    font-size: 6vmin;
    font-weight: 500;
    margin-top: 60px;
    margin-bottom: 25px;
    margin-left: 160px;
}

.reviewsGrid {
    display: flex;
    position: relative;
    left: 140px;
    width: 1150px;
}

.speechBubble {
    position: relative;
    background: #f7f7f7; /* light gray background */
    border-radius: 40px;
    padding: 20px;
    width: 400px;
    height: 250px;
    line-height: 1.5;
    margin: 10px; /* Add some margin for better visibility */
}

.speechBubble p{
    font-size: 18px;
    font-weight: 500;
}

.reviewPercentage {
    width: 290px;
    background-color: #F180EF;
    color: white;
}

.reviewPercentage h2{
    color: white;
    font-size: 32px;
    font-weight: 500;
}

.reviewPercentage p{
    font-size: 20px;
    font-weight: 500;
    width: 260px;
    position: relative;
    top: 13px;
    color: white !important;
}

.starsWrapper {
    display: flex;
    position: relative;
    top: -45px;
    left: 198px;
}

.bigStar {
    width: 50px;
    height: 50px;
    background-color: white;
    clip-path: polygon(
        50% 0%, 61% 35%, 98% 35%, /* Top points */
        68% 57%, 79% 91%,         /* Right points */
        50% 70%, 21% 91%,         /* Bottom points */
        32% 57%, 2% 35%, 39% 35%  /* Left points */
    );
}

.littleStar {
    width: 25px;
    height: 25px;
    background-color: white;
    clip-path: polygon(
        50% 0%, 61% 35%, 98% 35%, /* Top points */
        68% 57%, 79% 91%,         /* Right points */
        50% 70%, 21% 91%,         /* Bottom points */
        32% 57%, 2% 35%, 39% 35%  /* Left points */
    );
    position: relative;
    bottom: 10px;
    left: -15px;
} 

.review1 {
    width: 500px;
}

.review1::after {
    content: '';
    position: absolute;
    bottom: -5px; /* Adjust based on your layout */
    left: 458px; /* Adjust based on your layout */
    transform: translateX(-50%); /* Adjust horizontal alignment */
    width: 60px; /* This is the base of the equilateral triangle */
    height: calc(60px * 0.866); /* Height is calculated using the ratio (√3/2) ≈ 0.866 */
    background-color: #f7f7f7; 
    clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
    transform: rotate(25deg);
}

.review2::after {
    content: '';
    position: absolute;
    bottom: -5px; /* Adjust based on your layout */
    left: -18px; /* Adjust based on your layout */
    transform: translateX(-50%); /* Adjust horizontal alignment */
    width: 60px; /* This is the base of the equilateral triangle */
    height: calc(60px * 0.866); /* Height is calculated using the ratio (√3/2) ≈ 0.866 */
    background-color: #f7f7f7; 
    clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
    transform: rotate(-25deg);
}

.review4 {
    width: 240px;
    height: 530px;
}

.review4 p{
    position: relative;
    top: 10px;
}

.review4::after {
    content: '';
    position: absolute;
    bottom: -5px; /* Adjust based on your layout */
    left: 198px; /* Adjust based on your layout */
    transform: translateX(-50%); /* Adjust horizontal alignment */
    width: 60px; /* This is the base of the equilateral triangle */
    height: calc(60px * 0.866); /* Height is calculated using the ratio (√3/2) ≈ 0.866 */
    background-color: #f7f7f7; 
    clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
    transform: rotate(25deg);
}

.reviewsRight {
    width: 50px;
    margin: 0;
}

/* Text styling */
.speechBubble p {
    margin: 0;
    color: #333; /* text color */
}

.reviewsLeftTop {
    display: flex;
}

.reviewsLeftBottom {
    display: flex;
}

/* Background Objects */
.bgObjectSonic {
    --initial-width: 25vw;
    width: var(--initial-width);
    height: auto;
    left: 75vw;
    top: 272vw;
}

.bgObjectPasta {
    --initial-width: 20vw;
    width: var(--initial-width);
    height: auto;
    left: 0vw;
    top: 267vw;
    z-index: 1;
}


/* Mobile */
@media (max-width: 414px) {
    html, body {
        overflow-x: hidden; /* Disable horizontal scrolling */
        min-width: 100vw; /* Ensure the width is set to the viewport width */
        margin: 0;
        padding: 0;
    }

    .reviewsSectionContainer {
        width: 100vw;
        height: 250vw;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .reviewsSectionContent {
        width: 95vw;
        height: 250vw;
        position: relative;
        top: 0vw;
        left: 0;
        right: 0;
        margin: 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-top: -80vw;
        margin-bottom: -45vw;
    }

    .reviewsSectionTitle {
        font-size: 5vw;
        margin-bottom: 0vw;
        width: 87vw;
        margin-left: 3vw;
    }

    .reviewsGrid {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 0;
        left: 0;
        width: 90vw;
    }

    .reviewsLeft {
        display: flex;
        flex-direction: column;
    }

    .reviewsLeftTop {
        display: flex;
        flex-direction: column;
    }

    .reviewsLeftBottom  {
        display: flex;
        flex-direction: column;
    }

    .speechBubble {
        width: 90vw;
        height: 40vw;
        border-radius: 5vw;
        position: relative;
        right: 0vw;
        margin: 2vw;
    }

    .speechBubble p {
        font-size: 3vw;
    }

    .reviewPercentage {
        height: 25vw;
    }

    .reviewPercentage h2 {
        font-size: 6vw;
    }

    .reviewPercentage p {
        font-size: 3vw;
        top: -50px;
    }

    .starsWrapper {
        left: 67.5vw;
        top: -10vw;
    }

    .review1, .review3 {
        left: 3vw;
    }

    .review4 {
        right: 3vw;
    }

    .review4 {
        right: 40vw;
    }

    .review4::after {
        transform: translateX(-52vw) rotate(-25deg);
        z-index: -1;
    }

    .review1::after {
        top: 28vw;
        left: 79.5vw;
    }

    .review3::after {
        content: '';
        position: absolute;
        transform: translateX(-50%); /* Adjust horizontal alignment */
        width: 60px; /* This is the base of the equilateral triangle */
        height: calc(60px * 0.866); /* Height is calculated using the ratio (√3/2) ≈ 0.866 */
        background-color: #f7f7f7; 
        clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
        transform: rotate(25deg);
        top: 28vw;
        left: 79.5vw;
    }

    /* Background Objects on Mobile */
    .bgObjectSonic {
        --initial-width: 80vw;
        width: var(--initial-width);
        height: auto;
        left: 30vw;
        top: 200vw;
        display: none;
    }
    
    .bgObjectPasta {
        --initial-width: 80vw;
        width: var(--initial-width);
        height: auto;
        left: -20vw;
        top: 260vw;
        z-index: -1;
        display: none;
    }
    
}
