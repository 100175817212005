body {
    width: 100vmax;
    height: 100vmin;
    font-family: 'Helvetica', 'sans-serif';
}

/* Default font (for English) */
.titleFontEnglish {
    font-family: 'Stretch Pro', sans-serif;
}
  
/* Russian font */
.titleFontRussian {
    font-family: 'Rubik', sans-serif !important;
}
  
/* Chinese font */
.titleFontChinese {
    font-family: 'Source Han Sans CN Heavy', sans-serif !important;
}

.startSection {
    margin-top: 7vmin;
    display: flex;
    width: auto;
}

.startSectionContent {
    display: flex;
    width: auto;
}

.left {
    width: 50%;
    display: flex;
    flex-direction: column;
    gap: 10vmin;
    position: relative;
    top: 10vmin;
    left: 15vmin;
}

.regalia {
    display: flex;
    gap: 2vmin;
    margin-bottom: 10px;
    text-align: center; /* Horizontally center the text */
    padding: 1em; /* Padding around the text */
    font-size: 16px; /* Define a base font size */
    display: inline-flex; /* Use inline-flex to allow both horizontal and vertical centering */
    align-items: center; /* Vertically center the text */
    justify-content: center; /* Horizontally center the text */
    max-width: fit-content; /* Only take as much width as content requires */
    white-space: nowrap;
}

.regalia div {
    border: 1px solid black;
    border-radius: 25px;
    text-align: center;
    display: flex;
    height: 6vmin;
}

.regalia p {
    display: inline-block;
    font-size: 16px;
    padding: 2vmin;
    font-weight: 500;
    text-align: center;
}

.title h1 {
    font-size: 48px;
    font-weight: 500;
    position: relative;
    bottom: 50px;
    left: 1vw;
    line-height: 1.5;
    width: 600px;
}

.bulletPoints ul{
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    gap: 3.5vw;
    position: relative;
    left: 2vw;
    bottom: 70px;
}

.bulletPoints ul li {
    font-size: 1.5vw;
    font-weight: 500;
}

.right button {
    background-color:#F180EF;
    /* background: linear-gradient(to right, #FF4E50 10%, #F180EF 90%); */
    color: white;
    border: 0;
    border-radius: 40px;
    width: 230px;
    height: 70px;
    font-size: 24px;
    font-weight: 300;
    position: relative;
    bottom: 12.5vw;
}

.right button:hover {
    background-color:#FFA4FF;
    cursor: pointer;
}

.right {
    width: 60vw;
    margin: 0;
    padding: 0;
    border: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    left: 2vw;
}

.gifWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 60vw;
    height: 700px;
    position: relative;
    bottom: 11vw;
}

.facesGif {
    width: 55vw;
    margin: 0;
    padding: 0;
    border: 0;
}

.callInterface {
    width: 250px;
    position: relative;
    top: -200px;
}

/* Mobile */
@media (max-width: 414px) {
    html, body {
        overflow-x: hidden; /* Disable horizontal scrolling */
        min-width: 100vw; /* Ensure the width is set to the viewport width */
        margin: 0;
        padding: 0;
    }

    @font-face {
        font-family: 'Stretch Pro';
        src:  
              url('./assets/fonts/StretchPro.woff2') format('woff2'),
              url('./assets/fonts/StretchPro.woff') format('woff'),
              url('./assets/fonts/StretchPro.ttf')  format('truetype'),
              url('./assets/fonts/StretchPro.otf')  format('opentype'),
              url('./assets/fonts/StretchPro.svg') format('svg');
        font-weight: normal;
        font-style: normal;
        font-display: swap; /* Ensure text is visible while the font loads */
      }
      
    @font-face {
        font-family: 'Helvetica';
        src: url('./assets/fonts/Helvetica.ttc') format('truetype');
        font-weight: normal;
        font-style: normal;
    }
    
    .startSection {
        margin-top: 0vmin;
        height: 170vw;
        width: 100vw;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        position: relative;
        overflow-x: hidden;
        margin-bottom: 0vw;
    }

    .startSectionContent {
        width: auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        position: relative;
        height: 165vw;
        position: relative;
        margin-top: -20vw;
    }

    .left {
        display: flex;
        flex-direction: column;
        right: 0;
        left: 0;
        top: 0;
        bottom: 0;
        margin: 0;
        width: 90vw;
        gap: 5vw;
    }

    .right {
        display: flex;
        flex-direction: column;
        right: 0;
        left: 0vw;
        top: 15vw;
        bottom: 0;
        margin: 0;
        width: 90vw;
    }

    .title {
        order: 2;
        width: 90vw;

    }

    .title h1 {
        font-size: 4.5vw;
        top: -5vw;
        left: 0;
        width: 90vw;
        
    }

    .regalia {
        order: 1;
        position: relative;
        right: 0;
        left: -5vw;
        top: 0;
        bottom: 0;
        margin: 0;
        width: 100vw;
    }

    .regalia div{
        position: relative;
        min-width: 25vw;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .regalia div p{
        font-size: 2.5vw;
    }

    .bulletPoints {
        order: 3;
        position: relative;
        right: 0;
        left: 0;
        top: -5vw;
        margin: 0;
        align-items: center;
        justify-content: center;
        height: 25vw;
    }

    .bulletPoints ul{
        top: 0;
        bottom: 0;
        margin: 0;
        height: 25vw;
        position: relative;
        left: 4vw;
    }

    .bulletPoints li {
        height: 25vw;
        font-size: 3vw !important;
    }

    .gifWrapper {
        order: 4;
        position: relative;
        height: 20vw;
        right: 0;
        left: 0;
        top: 0vw;
        bottom: 0;
        margin: 0;
        align-items: center;
        justify-content: center;
        height: 40vw;
    }
    
    .facesGif {
        order: 4;
        position: relative;
        width: 110vw;
        height: 110vw;
        right: 0;
        left: 0;
        bottom: 5vw;
        margin: 0;
    }

    .callInterface {
        order: 4;
        width: 50vw;
        position: relative;
        top: -32vw;
        right: 0;
        left: 0;
        margin: 0;
    }

    .buttonContainer {
        order: 5;
        position: relative;
        right: 0;
        left: 0;
        top: 15vw;
        margin: 0;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .buttonContainer button {
        position: relative;
        right: 0;
        left: 0;
        top: 5vw;
        bottom: 0;
        margin: 0;
        width: 43vw;
        height: 15vw;
        font-size: 5vw;
        z-index: 1;
        border-radius: 2vw;
    }
}
