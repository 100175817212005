/* Default font (for English) */
.titleFontEnglish {
    font-family: 'Stretch Pro', sans-serif;
}
  
/* Russian font */
.titleFontRussian {
    font-family: 'Rubik', sans-serif !important;
}
  
/* Chinese font */
.titleFontChinese {
    font-family: 'Source Han Sans CN Heavy', sans-serif !important;
}

.SignUpContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 15vw;
    width: 100vw;
    height: 49vw;
    font-family: 'Helvetica', 'sans-serif';
}

.SignUpContent {
    display: flex;
    flex-direction: column;
    width: 30vw;
    height: 48vw;
    gap: 1vw;
}

.formWrapper {
    display: flex;
    flex-direction: column;
    gap: 1.5vw;
}

.fieldWrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.formWrapper label {
    font-weight: 300;
    font-size: 1.2vw;
    margin-bottom: 0.5vw;
}

.inputWrapper {
    width: 100%;
    display: flex;
}

.inputWrapper input {
    width: 100%;
    height: 3vw;
    border: 2px solid #C9C9C9;
    border-radius: 5px;
    padding: 10px;
    font-weight: 100;
}

.eye {
    width: 1.5vw;
    position: absolute;
    left: 62.5vw;
    margin-top: 0.8vw;
}

.eye:hover {
    cursor: pointer;
}

.registerButton {
    width: 100%;
    height: 3.2vw;
    border: none;
    background-color: #F180EF;
    font-size: 1.3vw;
    font-weight: 500;
    border-radius: 10px;
}

.registerButton:hover {
    cursor: pointer;
    background-color: #FFA4FF;
}

.error {
    color: rgb(255, 115, 115);
}

.resetTitleWrapper {
    margin-bottom: 20px;
    width: 90vw;
}

.resetTitle {
    font-size: 1.9vw;
    font-family: 'Stretch Pro', sans-serif
}


@media (max-width: 414px) {
    .resetTitle {
        font-size: 5vw;
        font-family: 'Stretch Pro', sans-serif
    }

    .SignUpContainer {
        position: relative;
        top: 10vw;
        height: 250vw;
    }

    .SignUpContent {
        width: 80vw;
        height: 130vw;
        margin-top: -100vw;
    }

    .formWrapper {
        gap: 3vw;
    }

    .formWrapper label{
        font-size: 3.5vw;
    }

    .inputWrapper input {
        height: 13vw;
        font-size: 3vw;
        padding: 5px;
    }

    .eye {
        width: 6vw;
        left: 80vw;
        margin-top: 4vw;

    }

    .rememberMeWrapper {
        margin-top: 2vw;
    }

    .rememberMeWrapper label {
        margin-left: 2vw;
        margin-bottom: 1.5vw;
    }

    .registerButton {
        margin-top: 2vw;
        height: 12vw;
        font-size: 5vw;
    }

    .error {
        font-size: 3vw;
    }
}

